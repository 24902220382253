/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


// alert css

.alertColors{
  --background: #1e1e1e;
  strong{
   color: var(--ion-color-light);
  }
  p{
    color: var(--ion-color-danger, #d11f37);
  }
}

.alertColors .btn-cancelar .sc-ion-alert-md {
  color: #e5e5e5 !important;
}

.alertColors .btn-eliminar .sc-ion-alert-md {
  color: var(--ion-color-danger, #d11f37);
  font-weight: bold
}

ion-modal.auto-heightTipoFaena{
  --height: 240px;
}

.alert-radio-label.sc-ion-alert-md {
  white-space: normal !important;
}

// ion select alert option width
.alert-wrapper{
    max-width: 550px !important;
    width: 100% !important;
}

ion-modal.reportado-modal{
    --height: 100% !important;
}
